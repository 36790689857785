import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { ReactComponent as KnabBlue } from '../../images/svg/knab-blue.svg';
import { ReactComponent as KnabWhite } from '../../images/svg/knab-white.svg';
import { ReactComponent as UnicefBlue } from '../../images/svg/unicef-blue.svg';
import { ReactComponent as UnicefWhite } from '../../images/svg/unicef-white.svg';

const mediaColor = [
  {
    id: 1,
    logo: (
      <StaticImage
        src="../../images/partners/ad-color.png"
        className="h-14 w-40"
        alt="Algemeen dagblad media logo"
      />
    )
  },
  {
    id: 2,
    logo: (
      <StaticImage
        src="../../images/partners/bloovi-color.png"
        className="h-14 w-40"
        alt="Bloovi media logo"
      />
    )
  },
  {
    id: 3,
    logo: (
      <StaticImage
        src="../../images/partners/linda-color.png"
        className="h-14 w-40"
        alt="Linda media logo"
      />
    )
  },
  {
    id: 4,
    logo: (
      <StaticImage
        src="../../images/partners/rtl_nieuws-color.png"
        className="h-14 w-40"
        alt="RTL Nieuws media logo"
      />
    )
  },
  {
    id: 5,
    logo: (
      <StaticImage
        src="../../images/partners/mt_sprout-color.png"
        className="h-14 w-40"
        alt="MT/Sprout media logo"
      />
    )
  },
  {
    id: 6,
    logo: (
      <StaticImage
        src="../../images/partners/telegraaf-color.png"
        className="h-14 w-40"
        alt="Telegraaf media logo"
      />
    )
  },
  {
    id: 7,
    logo: (
      <StaticImage
        src="../../images/partners/quote-color.png"
        className="h-14 w-40"
        alt="Quote media logo"
      />
    )
  },
  {
    id: 8,
    logo: (
      <StaticImage
        src="../../images/partners/ondernemer-color.png"
        className="h-14 w-40"
        alt="De Ondernemer media logo"
      />
    )
  }
];

const corporatesWhite = [
  {
    id: 1,
    logo: <KnabWhite className="h-14 w-28" />
  },
  {
    id: 2,
    logo: (
      <StaticImage
        src="../../images/partners/dpg-white.png"
        className="h-14 w-28"
        alt="dpg media logo"
      />
    )
  },
  {
    id: 3,
    logo: (
      <StaticImage
        src="../../images/partners/ov-white.png"
        className="h-14 w-28"
        alt="ov chipkaart logo"
      />
    )
  },
  {
    id: 4,
    logo: <UnicefWhite className="h-14 w-28" />
  },
  {
    id: 6,
    logo: (
      <StaticImage
        src="../../images/partners/odido-white.png"
        className="h-14 w-28"
        alt="Odido logo"
      />
    )
  },
  {
    id: 7,
    logo: (
      <StaticImage
        src="../../images/partners/simyo-white.png"
        className="h-14 w-28"
        alt="sim-yo logo"
      />
    )
  },
  {
    id: 8,
    logo: (
      <StaticImage
        src="../../images/partners/budgetthuis-white.png"
        className="h-14 w-28"
        alt="buddgetthuis logo"
      />
    )
  },
  {
    id: 9,
    logo: (
      <StaticImage
        src="../../images/partners/hetparool-white.png"
        className="h-14 w-28"
        alt="het parool logo"
      />
    )
  },
  {
    id: 10,
    logo: (
      <StaticImage
        src="../../images/partners/libelle-white.png"
        className="h-14 w-28"
        alt="libelle logo"
      />
    )
  },
  {
    id: 11,
    logo: (
      <StaticImage
        src="../../images/partners/devolkskrant-white.png"
        className="h-14 w-28"
        alt="de volkskrant logo"
      />
    )
  },
  {
    id: 12,
    logo: (
      <StaticImage src="../../images/partners/ben-white.png" className="h-14 w-28" alt="ben logo" />
    )
  },
  {
    id: 13,
    logo: (
      <StaticImage
        src="../../images/partners/interpolis-white.png"
        className="h-14 w-28"
        alt="interpolis logo"
      />
    )
  },
  {
    id: 14,
    logo: (
      <StaticImage
        src="../../images/partners/green-choice-white.png"
        className="h-14 w-28"
        alt="Green Choice logo"
      />
    )
  },
  {
    id: 15,
    logo: (
      <StaticImage
        src="../../images/partners/aidsfonds-white.png"
        className="h-14 w-28"
        alt="Aidsfonds logo"
      />
    )
  },
  {
    id: 16,
    logo: (
      <StaticImage
        src="../../images/partners/simpel-white.png"
        className="h-14 w-28"
        alt="Simpel logo"
      />
    )
  }
];

const corporatesBlue = [
  {
    id: 1,
    logo: <KnabBlue className="h-14 w-28" />
  },
  {
    id: 2,
    logo: (
      <StaticImage
        src="../../images/partners/dpg-blue.png"
        className="h-14 w-28"
        alt="dpg media logo"
      />
    )
  },
  {
    id: 3,
    logo: (
      <StaticImage
        src="../../images/partners/ov-blue.png"
        className="h-14 w-28"
        alt="ov chipkaart logo"
      />
    )
  },
  {
    id: 4,
    logo: <UnicefBlue className="h-14 w-28" />
  },
  {
    id: 6,
    logo: (
      <StaticImage
        src="../../images/partners/odido-blue.png"
        className="h-14 w-28"
        alt="Odido logo"
      />
    )
  },
  {
    id: 7,
    logo: (
      <StaticImage
        src="../../images/partners/simyo-blue.png"
        className="h-14 w-28"
        alt="sim-yo logo"
      />
    )
  },
  {
    id: 8,
    logo: (
      <StaticImage
        src="../../images/partners/budgetthuis-blue.png"
        className="h-14 w-28"
        alt="buddgetthuis logo"
      />
    )
  },
  {
    id: 9,
    logo: (
      <StaticImage
        src="../../images/partners/hetparool-blue.png"
        className="h-14 w-28"
        alt="het parool logo"
      />
    )
  },
  {
    id: 10,
    logo: (
      <StaticImage
        src="../../images/partners/libelle-blue.png"
        className="h-14 w-28"
        alt="libelle logo"
      />
    )
  },
  {
    id: 11,
    logo: (
      <StaticImage
        src="../../images/partners/devolkskrant-blue.png"
        className="h-14 w-28"
        alt="de volkskrant logo"
      />
    )
  },
  {
    id: 12,
    logo: (
      <StaticImage src="../../images/partners/ben-blue.png" className="h-14 w-28" alt="ben logo" />
    )
  },
  {
    id: 13,
    logo: (
      <StaticImage
        src="../../images/partners/interpolis-blue.png"
        className="h-14 w-28"
        alt="interpolis logo"
      />
    )
  },
  {
    id: 14,
    logo: (
      <StaticImage
        src="../../images/partners/green-choice-blue.png"
        className="h-14 w-28"
        alt="Green Choice Logo"
      />
    )
  },
  {
    id: 15,
    logo: (
      <StaticImage
        src="../../images/partners/aidsfonds-blue.png"
        className="h-14 w-28"
        alt="Aidsfonds logo"
      />
    )
  },
  {
    id: 16,
    logo: (
      <StaticImage
        src="../../images/partners/simpel-blue.png"
        className="h-14 w-28"
        alt="Simpel logo"
      />
    )
  }
];

const uoColor = [
  {
    id: 1,
    logo: (
      <StaticImage
        src="../../images/partners/uitvaartzorg-bollenstreek.png"
        className="h-14 w-28"
        alt="uitvaartzorg bollenstreek"
      />
    )
  },
  {
    id: 2,
    logo: (
      <StaticImage
        src="../../images/partners/laetur-afscheid-zorg.png"
        className="h-14 w-28"
        alt="laetur afscheidzorg"
      />
    )
  },
  {
    id: 3,
    logo: (
      <StaticImage
        src="../../images/partners/stoppelenburg-uitvaartverzorging.png"
        className="h-14 w-28"
        alt="Stoppelenburg uitvaart"
      />
    )
  },
  {
    id: 4,
    logo: (
      <StaticImage
        src="../../images/partners/sjaardema.png"
        className="h-14 w-28"
        alt="sjaardema uitvaart"
      />
    )
  },
  {
    id: 5,
    logo: (
      <StaticImage
        src="../../images/partners/zonnewende.png"
        className="h-14 w-28"
        alt="zonnewende uitvaart logo"
      />
    )
  }
];

export { mediaColor, corporatesBlue, corporatesWhite, uoColor };
