const messages = {
  uo: 'Directeurs de funérailles',
  uo_singular: 'Directeur de funérailles',
  heir: 'Proches',
  heir_singular: 'Proche parent',
  company: 'Organisations',
  company_singular: 'Organisation',
  knowledgeBase: "Service d'assistance",
  caseStudies: 'Études de cas',
  contact: 'Contact',
  accept: 'Accepter',
  decline: 'Annuler',
  cookieBanner:
    'Nous utilisons des cookies pour améliorer votre expérience de navigation et analyser le trafic du site. En poursuivant votre navigation ou en fermant cette bannière, vous acceptez notre utilisation des cookies. En savoir plus à ce sujet dans notre',
  solutions: 'Solutions',
  resources: 'Ressources',
  partners: 'Partenaires',
  aboutUs: 'A propos de nous',
  legal: 'Mentions légales',
  team: 'Équipe',
  media: 'Presse et médias',
  vacancies: 'Postes vacants',
  privacy: 'Politique de confidentialité',
  cookies: 'Politique en matière de cookies',
  tos: 'Conditions générales',
  homeHeaderTitle: 'Nous vous aidons à régler la succession numérique.',
  homeHeaderSubTitle:
    "Sélectionnez l'option qui vous convient le mieux et nous essaierons de vous aider du mieux que nous pouvons.",
  homeHeaderButtonHeir: 'Proches',
  homeCard1Title: "Je veux régler les abonnements d'une personne décédée.",
  homeCard1Body: 'Non appliqué',
  homeCard2Title: 'Comment pouvons-nous offrir des soins appropriés aux personnes en deuil ?',
  homeCard2Body: 'Non appliqué',
  homeCard3Title:
    'Comment pouvons-nous réduire nos coûts opérationnels et maintenir la qualité de notre service à la clientèle ?',
  homeCard3Body: 'Non appliqué',
  homeCard4Title: "Comment devons-nous gérer l'héritage numérique de nos clients ?",
  homeCard4Body: 'Non appliqué',
  homeCard5Title:
    'Nous voulons être en mesure de fournir à nos clients les bons soins après un décès.',
  homeCard5Body: 'Non appliqué',
  homeCta1Heirs: 'annuler ou transférer des abonnements',
  homeCtaHowItWorks: 'Comment cela fonctionne-t-il ?',
  homeCta1Company: 'Visitez la page pour les organisations',
  homeCtaDemo: 'Réserver une démo',
  homeCtaNBL: 'Visiter Nabestaandenloket.nl',
  homeCtaMoreInfo: "Plus d'informations",
  heirHeaderTitle: 'Un moyen efficace et sûr de signaler un décès aux organisations',
  heirHeaderSubTitle:
    'Le service de résiliation de Closure vous permet de signaler gratuitement un décès dans plus de 1 600 organisations.',
  heirHeaderCta1: 'Trouver une organisation',
  heirHeaderCta2: 'Comment cela fonctionne-t-il ?',
  heirHowItWorksTitle: 'Comment cela fonctionne-t-il ?',
  heirHowItWorksSubTitle:
    "Grâce à l'un de nos partenaires, vous pouvez facilement commencer à résilier ou à transférer des abonnements.",
  heirHowItWorksStep1Title: 'Trouver un partenaire',
  heirHowItWorksStep1Body:
    "Vous pouvez commencer à utiliser notre service de résiliation par l'intermédiaire de l'un de nos partenaires tels que Odido ou OV-Chipkaart. Cliquez sur {lien} pour obtenir la liste de nos partenaires. ",
  heirHowItWorksStep2Title: 'Entrez les données',
  heirHowItWorksStep2Body:
    'Saisissez une fois les coordonnées du défunt et téléchargez le certificat de décès.',
  heirHowItWorksStep3Title: 'Informer toutes les organisations',
  heirHowItWorksStep3Body:
    'Sélectionnez toutes les organisations pour lesquelles vous souhaitez notifier un décès. Grâce à la plateforme de clôture, vous pouvez suivre le statut actuel des résiliations et des transferts.',
  heirCtaBoxTitle: 'Trouver un partenaire offrant le service de résiliation de Closure',
  heirCtaBoxBody:
    "Vous pouvez facilement utiliser le service de résiliation de Closure si vous avez un compte chez l'un de nos partenaires. Une fois que vous avez transmis le décès à ce partenaire, vous pouvez également transmettre immédiatement l'avis de décès à d'autres organisations.",
  heirCtaBoxButton: 'Tous les partenaires',
  companyHeaderTitle:
    'Une solution complète pour que votre organisation puisse gérer efficacement les notifications de décès.',
  companyHeaderSubTitle:
    "Le bureau du deuil de Closure, piloté par l'intelligence artificielle, aide les organisations dans l'ensemble du processus de déclaration d'un décès, à la fois par voie numérique et par téléphone.",
  companyHeaderCta1: 'Organiser une réunion de présentation',
  companyHeaderCta2: 'Comment cela fonctionne-t-il ?',
  uspTitle: 'Découvrez les avantages de notre service pour votre secteur.',
  uspChooseIndustry: "Sélectionnez votre secteur d'activité",
  uspCompanyTitle: 'Pour votre organisation',
  uspHeirTitle: 'Pour vos clients',
  uspHowItWorksTitle: 'Comment cela fonctionne-t-il ?',
  uspHowItWorksBody:
    "Closure offre une solution numérique et téléphonique aux proches pour le traitement des abonnements de votre organisation. Ici, nous demandons aux clients de fournir une fois les coordonnées du défunt. Les données sont vérifiées à l'aide d'une technologie d'intelligence artificielle. Nous transmettrons ensuite une notification de premier ordre à votre organisation.",
  uspHowItWorksStep1Title: 'Accueil numérique et téléphonique',
  uspHowItWorksStep1Body:
    'Les besoins de votre organisation sont définis via un formulaire numérique pour compléter vos produits ou services. Pour répondre aux besoins de tous les groupes cibles, nous proposons, en plus du formulaire numérique, une assistance téléphonique pour le remplir.',
  uspHowItWorksStep2Title: 'Vérification de la notification du décès ',
  uspHowItWorksStep2Body:
    'Le logiciel intelligent de Closure et le service d\'assistance aux proches traiteront et valideront les notifications. La notification standardisée "du premier coup" est alors générée et transférée à l\'organisation.',
  uspHowItWorksStep3Title: "Traitement d'une notification de décès",
  uspHowItWorksStep3Body:
    'En fonction du type d\'intégration informatique, nous sommes en mesure de vous envoyer une notification de décès "du premier coup" avec la mise en page souhaitée ou via une intégration API. Dans les deux cas, une notification de décès complète est envoyée, ce qui vous fait gagner beaucoup de temps.',
  uspTestimonialTitle: 'Critiques',
  uspTestimonialSubTitle:
    'Tant pour les organisations que pour les proches, Closure est la solution la plus conviviale et la plus efficace pour régler les abonnements après un décès.',
  uspTypeIntegrationTitle: "Type d'intégration",
  uspTypeIntegrationLow: 'Informatique à impact zéro',
  uspTypeIntegrationMedium: 'Informatique à impact moyen',
  uspTypeIntegrationHigh: 'Informatique à fort impact',
  uspTypeIntegrationLowTitle: "Solution sans impact sur l'informatique",
  uspTypeIntegrationLowBody:
    "Nous savons que les feuilles de route informatiques des organisations sont complexes. Par conséquent, le service de Closure peut être mis en œuvre dans votre organisation sans nécessiter l'engagement de votre département informatique. Nous envoyons des notifications de décès vérifiées à la première occasion par des courriels standardisés à votre back-office. En comprenant parfaitement vos processus, nous vous libérons de la charge de la vérification des données personnelles, des contrôles de documents et de l'envoi de rappels répétitifs pour corriger les données erronées.",
  uspTypeIntegrationMediumTitle: 'Solution informatique à impact moyen',
  uspTypeIntegrationMediumBody:
    'Pour un flux de travail plus efficace, nous téléchargeons les notifications de décès vérifiées et les documents requis dans votre source de données. Cette notification est ensuite facilement traitée par votre back office.',
  uspTypeIntegrationHighTitle: 'Solution informatique intégrée',
  uspTypeIntegrationHighBody:
    "Grâce à des intégrations API personnalisées, nous pouvons traiter les notifications de décès directement dans votre back-office ou votre solution CRM. Grâce à cela, nous supprimons toutes les tâches répétitives lors du règlement d'un décès.",
  companyFooterCtaTitle: 'Organiser une réunion de présentation',
  companyFooterCtaBody:
    "Planifiez un appel d'introduction gratuit pour voir comment Closure peut vous aider à améliorer le traitement des avis de décès dans votre organisation.",
  companyFooterCtaButton: 'Organiser une réunion de présentation',
  modalDemoTitle: 'Organiser une réunion de présentation',
  modalDemoBody:
    "Lors de la réunion d'introduction, nous serons heureux de vous expliquer plus en détail comment Closure peut soutenir votre organisation.",
  modalDemoEmail: 'Adresse électronique',
  modalDemoName: 'Nom',
  modalDemoCompany: 'Organisation',
  modalDemoButton: 'Organiser une réunion de présentation',
  uoHeaderTitle: 'La plateforme complète de suivi de vos proches',
  uoHeaderSubTitle:
    'Aftercare Counter a été développé en collaboration avec le secteur funéraire pour regrouper sur une plateforme numérique, dans le style de votre maison, toutes les questions relatives aux soins après un décès.',
  uoHeaderButton: 'Visiter Nabestaandenloket.nl',
  uoServiceTitle: "Un suivi complet pour s'occuper de toutes les questions après un décès.",
  uoServiceBody:
    'Vous pouvez offrir le Nabestaandenloket à vos proches dans votre propre style de maison.',
  uoService1Title: 'Service de résiliation de compte',
  uoService1Body:
    'Le service de résiliation de Closure est inclus dans la plateforme Nabestaandenloket. Permettez à vos proches de commencer à informer les organisations facilement et rapidement après un décès.',
  uoService2Title: 'Étendre votre service',
  uoService2Body:
    "Toutes les tâches administratives importantes à effectuer après un décès sont présentées sur une plateforme conviviale. Vos clients peuvent gérer ces tâches, ou désigner d'autres proches pour cela.",
  uoService3Title: "Service d'assistance",
  uoService3Body:
    "Notre service d'assistance spécialisé est disponible par téléphone, par chat et par courrier électronique pour vos proches afin de vous aider dans toutes les questions relatives à l'assistance.",
  uoFeature1Title:
    'Annulez ou transférez facilement et rapidement tous les abonnements après un décès.',
  uoFeature1Body:
    "Le service de résiliation de Closure est intégré à la plateforme Nabestaandenloket. Cela signifie que vos proches peuvent avoir une vue d'ensemble tant sur les questions administratives que sur le service de licenciement. Ils y trouveront également un aperçu de l'état actuel de toutes les notifications.",
  uoFeature2Title: 'Traitement facile et guidé de toutes les questions administratives',
  uoFeature2Body:
    'Un guide structuré autour de toutes les questions administratives qui doivent être réglées après un décès. Pour chaque catégorie, il explique clairement la manière dont certaines matières doivent être organisées.',
  uoFeature3Title: "Page d'adieu en ligne",
  uoFeature3Body:
    "Une page d'adieu personnalisée pour les proches afin de susciter plus d'engagement autour des funérailles. Cette page permet de trouver facilement toutes les informations relatives aux funérailles, y compris les souvenirs, les documents importants, les lieux et la musique.",
  uoSecondaryBenefitsTitle: 'Pourquoi choisir Survivor Counter ?',
  uoSecondaryBenefits1Title: 'Service de résiliation inclus',
  uoSecondaryBenefits1Body: 'Description',
  uoSecondaryBenefits2Title: 'Dans votre propre style de maison',
  uoSecondaryBenefits2Body: 'Description',
  uoSecondaryBenefits3Title: "Prolonger vos services jusqu'après les funérailles",
  uoSecondaryBenefits3Body: 'Description',
  uoSecondaryBenefits4Title: 'Opérations administratives',
  uoSecondaryBenefits4Body: 'Description',
  uoSecondaryBenefits5Title: "Page d'adieu en ligne",
  uoSecondaryBenefits5Body: 'Description',
  uoSecondaryBenefits6Title: 'Soutien aux proches',
  uoSecondaryBenefits6Body: 'Description',
  uoSecondaryBenefits7Title: 'Inviter les membres de la famille',
  uoSecondaryBenefits7Body: 'Description',
  uoSecondaryBenefits8Title: 'Intégration facile',
  uoSecondaryBenefits8Body: 'Description',
  uoSecondaryBenefits9Title: "Comprendre l'usage",
  uoSecondaryBenefits9Body: 'Description',
  uoNBLDeskTitle: 'Soutien à vos proches',
  uoNBLDeskBody:
    "Notre service d'assistance aux proches est spécialisé dans l'accompagnement des proches. Vos proches peuvent s'adresser à nous pour toutes sortes de questions concernant les questions administratives après un décès.",
  uoNBlDeskBenefitFeature1: 'uoNBlDeskBenefitFeature1',
  uoNBlDeskBenefitFeature2:
    'Ouvert du lundi au vendredi (08h30 - 21h00) et le samedi (09h00 - 13h00).',
  uoNBlDeskBenefiFeature3: 'Joignable par téléphone, par chat et par courriel.',
  teamHeaderCategory: 'Notre mission',
  teamHeaderTitle:
    'Transformer les moments clés de la vie en de belles expériences, tant pour les personnes que pour les organisations.',
  teamOurStoryCategory: 'Notre histoire',
  teamOurStoryTitle: 'Tout a commencé...',
  teamOurStoryBody:
    "Nous connaissons la douleur qui accompagne un décès. Non seulement sur le plan émotionnel, mais aussi sur le plan administratif, cela représente une charge. Ayant fait cette expérience il y a quelques années, Closure est né pour développer une solution permettant de faciliter le règlement des abonnements pour les proches. Non seulement les proches, mais aussi les organisations bénéficient du service d'annulation de Closure. En fait, nous faisons gagner beaucoup de temps et d'efforts aux organisations en leur fournissant des notifications de décès vérifiées dès le départ. Depuis longtemps, Closure propose une solution numérique. Cependant, il y a aussi un grand groupe de personnes aux Pays-Bas qui sont un peu moins à l'aise avec elle. C'est pourquoi nous avons élargi notre service à la fin de l'année 2022 pour y inclure un bureau d'assistance spécialisé dans le deuil, joignable par téléphone, chat, e-mail et courrier. Nous avons pour mission de traiter d'autres notifications importantes pour les clients que les avis de décès. Notre mission est donc aujourd'hui la suivante : transformer les moments clés de la vie en expériences exceptionnelles pour les personnes et les organisations.",
  teamStat1Value: '1/3',
  teamStat1Title: 'De tous les décès aux Pays-Bas sont actuellement traités par Closure.',
  teamStat2Value: '9.6',
  teamStat2Title: 'Score NPS du survivant',
  teamStat3Title: 'Dans le cadre du programme de lancement Goldman Sachs 2022',
  teamSectionTitle: "Rencontrez l'équipe derrière Closure",
  teamHiringTitle: 'Fermeture des joints',
  teamHiringBody:
    "Vous avez besoin d'un travail stimulant au sein d'une entreprise à croissance rapide ? Consultez nos postes vacants.",
  teamHiringFeature1: 'Salaire basé sur le marché',
  teamHiringFeature2: 'Travail hybride et proximité de la gare centrale de Rotterdam',
  teamHiringFeature3: 'Une équipe jeune et motivée de type A-player',
  teamHiringButton: 'Postes vacants',
  mediaHeaderTitle: 'Presse et médias',
  mediaArticleButton: 'Lire la suite',
  partnersHeaderTitle: 'Trouver une organisation',
  partnerPlaceHolder: 'Par exemple, "AD".',
  partnersHeaderBody:
    "Trouvez une organisation qui offre le service d'annulation de Closure. Par l'intermédiaire de ce partenaire, vous pouvez également cocher directement tous les autres abonnements à résilier ou à transférer.",
  partnersCardButtonBegin: 'Début',
  partnersCardButtonMoreInfo: "Plus d'informations",
  partnersModalInfoTitle: 'Partenaires non officiels',
  partnersModalInfoBody:
    "Ne vous inquiétez pas, vous pouvez également annuler ou transférer un compte chez nos partenaires non officiels. Pour ce faire, vous devez commencer par un partenaire officiel. Vous pouvez ensuite vérifier tous les autres abonnements par l'intermédiaire de ce partenaire. Cliquez ci-dessous pour voir nos partenaires officiels.",
  partnersModalInfoButton: 'Fermer',
  contactCategory: 'Contact',
  contactHeaderTitle: 'Nous sommes heureux de vous aider.',
  contactHeirTitle: 'Pour les proches',
  contactHeirBody:
    'En tant que parent survivant, vous avez des questions sur nos services ? Nous serons heureux de vous aider.',
  contactHeirButton: 'Chattez avec nous',
  contactHeirOptional: 'ou appelez',
  contactSalesTitle: 'Pour les organisations',
  contactSalesBody:
    'Laissez vos coordonnées ici et nous vous contacterons dans les plus brefs délais.',
  contactSalesButton: 'Réserver une démo',
  contactMediaTitle: 'Appuyez sur',
  contactMediaBody: 'Les demandes de presse peuvent être envoyées par e-mail à pers@closure.nl.',
  contactMediaButton: 'Courriel :',
  caseStudiesHeaderTitle: 'Études de cas',
  caseStudiesHeaderBody:
    "Vous trouverez ci-dessous plusieurs exemples de réussite démontrant comment nous prenons en charge l'intégralité de la prise en charge du deuil au sein des organisations.",
  readmore: 'Lire la suite',
  newsletter: "S'abonner à notre newsletter",
  afm: "Closure est agréé par l'Autorité des marchés financiers (AFM) pour signaler le décès de prestataires de services financiers.",
  copyright:
    'Closure B.V. / Stationsplein 45 - Duimte D3.111 / 3013AK Rotterdam KVK : 70792828 © Closure 2023. Tous droits réservés.',
  emailPlaceholder: 'Adresse électronique',
  namePlaceholder: 'Nom complet',
  companyPlaceholder: "Nom de l'organisation",
  organisationsSearchPlaceholder: "Recherche d'organisations",
  faqTitle: 'Questions fréquemment posées',
  uoFooterCTATitle: 'Visiter Nabestaandenloket.nl',
  uoFooterCTABody:
    "Planifiez une démonstration gratuite pour voir comment Next of kinLoket s'intègre dans vos services en tant que directeur de funérailles.",
  uoFooterCTAButton: 'Visiter Nabestaandenloket.nl',
  uoCTA2Text: 'ou appelez ',
  uoPhoneNumber: '085 06 45 744',
  tMobileCaseStudySummary:
    'Avec un sujet aussi sensible, vous ne voulez pas faire attendre les gens inutilement, ou faire quelque chose de mal " Bas Jansen, directeur des opérations chez Odido.',
  ovChipkaartCaseStudySummary:
    'Dans cette étude de cas, nous détaillons notre collaboration avec OV-Chipkaart, le système de paiement des transports publics néerlandais.',
  DPGMediaCaseStudySummary:
    "Dans cette étude de cas, nous expliquons notre collaboration avec DPG Media, le géant des médias à l'origine de Het Parool et Libelle, entre autres.",
  newsletterModalTitle: 'Inscription à la newsletter',
  newsletterModalBody:
    'Inscrivez-vous à notre newsletter pour rester informé des derniers développements de Closure.',
  newsletterButton: "S'inscrire",
  alreadyAccount: 'Ou connectez-vous si vous avez déjà un compte',
  searchPartnersPlaceholder: "Recherche d'organisations",
  backToHome: "Retour à la page d'accueil",
  teamSectionGoldmanSachs: 'Nous faisons partie du Goldman Sachs Launch Program 2022.',
  teamSectionPartnersProgram:
    'Au fil des ans, nous avons réalisé beaucoup de choses, voici quelques-uns des programmes et des prix.',
  organisationPlaceholder: 'Organisation',
  officialPartners: 'Cliquez ici pour nos partenaires officiels',
  errorTitlePartners: 'Quelque chose a mal tourné',
  errorBodyPartners: 'Rafraîchissez la page et réessayez',
  phonenumber: 'Numéro de téléphone',
  privacyPolicy: 'politique de confidentialité',
  errorMsgDemo: 'Veuillez remplir tous les champs obligatoires.',
  successMsgDemo: 'La demande a été envoyée avec succès',
  notificationsCaseStudy: 'rapports',
  'languages.nl-NL': 'Naar Nederlands',
  'languages.nl-BE': 'Naar Nederlands',
  'languages.fr-BE': 'Au français',
  'languages.fr-FR': 'Au français',
  'url.algemene-voorwaarden': '/termes-et-conditions',
  'url.case-studies': '/etudes-de-cas',
  'url.contact': '/contactez',
  'url.organisaties': '/organisations',
  'url.partners': '/partenaires',
  'url.pers-en-media': '/presse-et-medias',
  'url.nabestaanden': '/proches',
  'url.vacatures': '/postes-vacants',
  'url.team': '/equipe',
  'url.privacy': '/privacy',
  'url.cookies': '/cookies',
  'url.uitvaart-ondernemers': '/pompes-funebres',
  'url.case-studies/t-mobile': '/etudes-de-cas/t-mobile',
  'url.case-studies/ov-chipkaart': '/etudes-de-cas/ov-chipkaart',
  portalUrl: 'https://app.closure.fr/fr/portal/login',
  verifiedByClosure: 'Vérifié par la fermeture',
  deathNotificationVerifiedByClosure: 'Déclaration de décès vérifiée',
  deathNotification: 'notification de décès',
  moreInfo: "Plus d'infos",
  vacatures: 'Postes vacants',
  viewButton: 'Regarder',
  updateTOSDate: '18 mai 2022',
  here: 'ici'
};

export default messages;
