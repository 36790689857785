import { HeadProps } from 'gatsby';
import React from 'react';

import FooterCTA from '../../components/FooterCTA';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import SimpleHeader from '../../components/SimpleHeader';
import PrivacySEO from '../../content/seo/privacy';
import HeadParent from '../../utils/header';

function Privacy({ pageContext }) {
  return (
    <Layout pageContext={pageContext} navTextColor="dark" navColor="bg-darkwhite">
      <SimpleHeader title="Closure - Privacy" lastUpdated="29 mei 2022" />
      <Section bgColor="bg-offwhite" paddingTop="pt-12 lg:pt-28" paddingBottom="pb-12 lg:pb-28">
        <div className="mx-auto max-w-3xl">
          <div className="space-y-4 text-base text-plum">
            <h1 id="-het-privacybeleid-van-closure-" className="text-lg text-plum">
              <strong>Het Privacybeleid van Closure</strong>
            </h1>
            <p>
              Dit is het Privacybeleid van Closure B.V. (hierna te noemen “<strong>Closure</strong>
              ”, “<strong>wij</strong>,” “<strong>ons</strong>” of “<strong>onze</strong>”),
              gevestigd te Stationsplein 45 - A7.209, 3013 AM Rotterdam. Closure is ingeschreven bij
              de Kamer van Koophandel onder nummer 70792828.
            </p>
            <p>
              Closure respecteert uw privacy en doet er alles aan om uw persoonsgegevens te
              beschermen. We vinden het belangrijk dat onze service transparant, persoonlijk en
              betrouwbaar is. We zijn dan ook voortdurend op zoek naar manieren om onze
              dienstverlening te verbeteren en deze zoveel mogelijk af te stemmen op uw persoonlijke
              wensen en behoeften. Daarbij verwerken we uw persoonsgegevens zorgvuldig en veilig.
            </p>
            <p>
              Ons Privacybeleid legt uit hoe wij persoonsgegevens verwerken in relatie tot onze
              website www.closure.nl en applicatie app.closure.nl (de “Website”). Wij verzamelen
              deze gegevens wanneer u onze Website bezoekt met uw computer, tablet of mobiele
              telefoon. Wij verwerken persoonsgegevens in overeenstemming met de Algemene
              Verordening Gegevensbescherming (de “AVG”), de op de AVG gebaseerde
              uitvoeringswetgeving en de andere op dit moment geldende privacywetgeving. Zie onder
              punt 2 een overzicht van de persoonsgegevens die wij van u verwerken.
            </p>
            <p>
              Dit Privacybeleid is van toepassing op al onze klanten en alle bezoekers van onze
              website.
            </p>
            <h1 id="-2-welke-persoonsgegevens-verzamelen-wij-">
              <strong>2. Welke persoonsgegevens verzamelen wij?</strong>
            </h1>
            <p>We verzamelen de volgende gegevens:</p>
            <p>
              <em>Contact- en identificatiegegevens</em>, zoals uw naam, e-mailadres, woonplaats,
              geboortedatum en telefoonnummer.
            </p>
            <p>
              <em>Gegevens inzake uw online account,</em> zoals uw gebruikersnaam en wachtwoord
              wanneer u zich registreert voor een Closure account. Verder vragen wij bij registratie
              ook om het uploaden van een overlijdensakte en een machtiging van Closure;
            </p>
            <p>
              <em>Elektronische gegevens</em>, gegevens die automatisch worden verzameld bij het
              bezoeken van onze website via uw browser of mobiele apparaat (zoals bijvoorbeeld uw
              IP-adres) of door middel van het gebruik van cookies en soortgelijke technieken.
              Bekijk ons Cookiebeleid voor meer informatie;
            </p>
            <p>
              <em>Financiële gegevens</em>, zoals uw bankrekeningnummer, factuurgegevens en uw keuze
              voor de wijze van betaling;
            </p>
            <p>
              <em>De inhoud van uw communicatie met ons,</em> bijvoorbeeld wanneer u ons per e-mail
              of telefonisch contacteert.
            </p>
            <h1 id="-3-hoe-gebruiken-wij-deze-gegevens-">
              <strong>3. Hoe gebruiken wij deze gegevens?</strong>
            </h1>
            <p>
              Wij verzamelen en gebruiken uw persoonsgegevens voor de doeleinden zoals hieronder
              uiteengezet, elk gebaseerd op een wettelijke grondslag.
            </p>
            <p>
              <strong>Voor de uitvoering van onze overeenkomst met u</strong>
            </p>
            <p>
              Wij verzamelen en verwerken om de diensten te kunnen leveren die u van ons verlangt en
              om onze (financiële) administratie te verzorgen. Zo hebben wij bijvoorbeeld een
              overlijdensakte nodig ter verificatie van uw dierbare en hebben wij een machtiging
              nodig om namens u diensten te beëindigen of over te zetten. Daarnaast verwerken wij uw
              persoonsgegevens om met u te communiceren en om eventuele verzoeken, klachten of
              vragen te behandelen.
            </p>
            <p>
              <strong>Voor onze gerechtvaardigde bedrijfsbelangen</strong>
            </p>
            <p>
              Wij verwerken uw persoonsgegevens ook om de doeltreffendheid van onze dienstverlening
              en website te verbeteren en om onze klanten en Website-bezoekers beter te begrijpen.
              Zo onthouden we bijvoorbeeld uw inloggegevens zodat u niet steeds opnieuw hoeft in te
              loggen wanneer u de website bezoekt.
            </p>
            <p>
              <strong>
                Om te voldoen aan onze wettelijke verplichtingen en schade te voorkomen
              </strong>
            </p>
            <p>
              Wij verwerken uw persoonsgegevens ook om te voldoen aan wettelijke verzoeken van
              overheidsinstanties of de rechter (zoals een huiszoekingsbevel, gerechtelijk bevel of
              een dagvaarding) en om te voldoen aan toepasselijke wet- en regelgeving. Wij zijn ook
              gerechtigd om uw informatie te bewaren en/of te delen wanneer wij geloven dat dit
              noodzakelijk is voor het opsporen, voorkomen en aankaarten van fraude of andere
              illegale activiteiten en om ons, u en anderen te beschermen.
            </p>
            <h1 id="-4-hoe-worden-deze-gegevens-gedeeld-">
              <strong>4. Hoe worden deze gegevens gedeeld?</strong>
            </h1>
            <p>
              Alleen geautoriseerde werknemers van Closure hebben toegang tot uw persoonsgegevens
              voor zover dat noodzakelijk is in het kader van de verlening van onze dienst.
            </p>
            <p>
              In het kader van onze dienstverlening aan u, delen wij uw gegevens met de
              dienstverleners waarbij u abonnementen of contracten wil opzeggen of overdragen.
              Tevens kunnen jouw gegevens verwerkt worden met IT-dienstverleners die door ons als
              verwerker worden ingeschakeld en louter conform onze instructie de gegevens mogen
              verwerken. Wij delen nooit persoonsgegevens met bedrijven waarvan u ons niet
              uitdrukkelijk hebt gevraagd om er een abonnement op te zeggen of te wijzigen. Wij
              verkopen ook nooit uw persoonsgegevens aan derden.
            </p>
            <h1 id="-5-beveiliging-van-uw-gegevens-">
              <strong>5. Beveiliging van uw gegevens</strong>
            </h1>
            <p>
              Closure heeft passende technische en organisatorische maatregelen genomen om uw
              gegevens te beveiligen tegen verlies of tegen enige vorm van onrechtmatige verwerking.
              Wij gebruiken veiligheidsmaatregelen om te zorgen dat informatie via de Website wordt
              beveiligd (zoals het aanvragen van een uniek wachtwoord).
            </p>
            <h1 id="-6-internationale-doorgifte-van-gegevens-">
              <strong>6. Internationale doorgifte van gegevens</strong>
            </h1>
            <p>
              Uw informatie kan worden overgedragen en bewaard worden op servers die zich buiten
              Nederland en/of de EU bevinden en waar de wetten omtrent gegevensbescherming kunnen
              verschillen. Wanneer dit het geval is zorgen wij voor passende aanvullende waarborgen,
              uit hoofde van artikel 46 AVG, om de bescherming van uw persoonsgegevens te
              waarborgen. Voor zover onze IT-dienstverleners zich buiten de EU bevinden, vindt
              doorgifte plaats op basis van standard contractual clauses of op basis van een
              <a href="https://www.privacyshield.gov/list">Privacy Shield</a>-certificering. Op uw
              verzoek kan u ons een kopie vragen van de toepasselijke grondslag voor deze doorgifte
              van uw persoonsgegevens.
            </p>
            <h1 id="-7-bewaartermijn-">
              <strong>7. Bewaartermijn</strong>
            </h1>
            <p>
              In overeenstemming met de AVG en de overige relevante wetgeving, bewaart Closure
              persoonsgegevens niet langer dan noodzakelijk is voor de verwezenlijking van de
              doeleinden waarvoor zij verzameld of verwerkt worden. Dat is 6 maanden na het laatste
              contactmoment.
            </p>
            <p>
              De criteria die zijn gebruikt om onze bewaartermijnen te bepalen, omvatten onder
              andere:
            </p>
            <ul>
              <li>
                de periode gedurende welke we een lopende relatie met u hebben (zoals uw Closure
                account);
              </li>
              <li>
                of er sprake is van een wettelijke bewaartermijn waaraan Closure onderworpen is; en
              </li>
              <li>
                of bewaring wenselijk of noodzakelijk is in het licht van onze juridische positie
                (bijvoorbeeld in verband met de handhaving van onze algemene voorwaarden,
                rechtszaken of juridisch onderzoek).
              </li>
            </ul>
            <h1 id="-8-uw-gegevens-beheren-bekijken-en-updaten-">
              <strong>8. Uw gegevens beheren, bekijken en updaten</strong>
            </h1>
            <p>
              Op grond van de toepasselijke wet- en regelgeving heeft u de volgende rechten ten
              aanzien van uw persoonsgegevens: recht op inzage, recht op rectificatie, recht van
              verzet, recht op beperking, recht op wissing, recht van bezwaar en recht op
              overdraagbaarheid van uw gegevens.
            </p>
            <p>
              In uw Closure account kunt u op ieder moment uw persoonsgegevens zelf inzien en deze
              rechten uitoefenen door uw persoonsgegevens te corrigeren of verwijderen. Voor vragen
              of meer informatie kan u een e-mail sturen naar
              <a href="mailto:info@closure.nl">info@closure.nl</a>.
            </p>
            <p>
              Verder hebt u ook het recht om een klacht in te dienen bij de Autoriteit
              Persoonsgegevens indien u van mening bent dat uw persoonsgegevens door ons in strijd
              met dit Privacybeleid worden verwerkt.
            </p>
            <h1 id="-9-privacy-van-kinderen-">
              <strong>9. Privacy van kinderen</strong>
            </h1>
            <p>
              Onze website vraagt niet specifiek en bewust om gegevens van personen jonger dan 16
              jaar (“Kinderen”). Indien wij erachter komen dat wij persoonsgegevens hebben verzameld
              van Kinderen zonder toestemming van hun ouder of voogd, dan zullen wij stappen
              ondernemen om deze gegevens te verwijderen van onze servers. Als u vermoedens heeft
              dat uw kind zonder uw toestemming persoonsgegevens heeft verstrekt aan ons, dan kunt u
              contact met ons opnemen via info@closure.nl. Indien u als kind persoonsgegevens achter
              hebt gelaten op onze site en u deze wil verwijderen, dan zullen wij ons inspannen om
              deze gegevens te verwijderen. Als u het vermoeden heeft dat u als kind
              persoonsgegevens aan ons heeft verstrekt, kunt u contact met ons opnemen via
              info@closure.nl.
            </p>
            <h1 id="-10-wijzigingen-">
              <strong>10. Wijzigingen</strong>
            </h1>
            <p>
              Wij kunnen van tijd tot tijd dit Privacybeleid aanpassen of updaten. U wordt daarom
              geadviseerd om deze pagina regelmatig te raadplegen. We zullen u echter op de hoogte
              houden van fundamentele wijzigingen aan dit Privacybeleid.
            </p>
            <h1 id="-11-contact-">
              <strong>11. Contact</strong>
            </h1>
            <p>
              Wanneer u vragen heeft over dit Privacybeleid, dan kunt u contact opnemen met Closure
              door een e-mail te sturen naar <a href="mailto:info@closure.nl">info@closure.nl</a> of
              een brief sturen aan:
            </p>
            <p>
              Closure B.V.
              <br />
              Stationsplein 45
              <br />
              Ruimte D3.111
              <br />
              3013AK Rotterdam
            </p>
          </div>
        </div>
      </Section>
      <FooterCTA bgInsideColor="bg-darkwhite" bgColor="bg-darkwhite" />
    </Layout>
  );
}

export default Privacy;

export function Head(props: HeadProps) {
  const { location } = props;
  return (
    <HeadParent pageTitle="Privacy" location={location}>
      <PrivacySEO />
    </HeadParent>
  );
}
