import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const teamMembers = [
  {
    id: 1,
    name: 'Graciëlla van Vliet',
    img: (
      <StaticImage
        src="../../images/team/graciella.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="graciella"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Co-founder | CEO',
  },
  {
    id: 2,
    name: 'Bart Verhaegh',
    img: (
      <StaticImage
        src="../../images/team/bart.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="bart"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Co-founder | CTO',
  },
  {
    id: 3,
    name: 'Michael Hilhorst',
    img: (
      <StaticImage
        src="../../images/team/michael.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="michael"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Product Lead',
  },
  {
    id: 4,
    name: 'Kevin Biguet',
    img: (
      <StaticImage
        src="../../images/team/kevin.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="kevin"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Commercial Lead',
  },
  {
    id: 5,
    name: 'Mark Meijhuis',
    img: (
      <StaticImage
        src="../../images/team/mark.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="mark"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Developer',
  },
  {
    id: 6,
    name: 'Iris van Mil',
    img: (
      <StaticImage
        src="../../images/team/iris.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="iris"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Support',
  },
  {
    id: 7,
    name: 'Charlotte van Eesteren',
    img: (
      <StaticImage
        src="../../images/team/unknown.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="charlotte"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Support',
  },
  {
    id: 8,
    name: 'Willemijn Bosman',
    img: (
      <StaticImage
        src="../../images/team/unknown.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="willemijn"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Support',
  },
  {
    id: 9,
    name: 'Vera Dubbink',
    img: (
      <StaticImage
        src="../../images/team/unknown.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="vera"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Support',
  },
  {
    id: 10,
    name: 'Sarafina Mooiweer',
    img: (
      <StaticImage
        src="../../images/team/unknown.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="sarafina"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Support',
  },
  {
    id: 11,
    name: 'Sophie van Steenbergen',
    img: (
      <StaticImage
        src="../../images/team/unknown.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="sophie"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Support',
  },
  {
    id: 12,
    name: 'Maud Jacobs',
    img: (
      <StaticImage
        src="../../images/team/unknown.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="maud"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Support',
  },
  {
    id: 13,
    name: 'Sander Visser',
    img: (
      <StaticImage
        src="../../images/team/unknown.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="sander"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Support',
  },
  {
    id: 14,
    name: 'Anne-Julia Karnbrock',
    img: (
      <StaticImage
        src="../../images/team/unknown.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="anne-julia"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Support',
  },
  {
    id: 15,
    name: 'Paulien Wulfers',
    img: (
      <StaticImage
        src="../../images/team/unknown.png"
        placeholder="none"
        className="mb-8 w-40 rounded-full"
        alt="paulien"
        imgStyle={{ borderRadius: '100%' }}
      />
    ),
    jobFunction: 'Support',
  },
];

// eslint-disable-next-line import/prefer-default-export
export { teamMembers };