import React from 'react';
import { FormattedMessage } from 'react-intl';

const oplossingen = [
  {
    id: 1,
    name: <FormattedMessage id="heir" />,
    url: 'nabestaanden'
  },
  {
    id: 2,
    name: <FormattedMessage id="company" />,
    url: 'organisaties'
  },
  {
    id: 3,
    name: <FormattedMessage id="uo" />,
    url: 'uitvaart-ondernemers'
  }
];

const resources = [
  // {
  //   id: 1,
  //   name: <FormattedMessage id="knowledgeBase" />,
  //   url: '/knowledge-base'
  // },
  {
    id: 2,
    name: <FormattedMessage id="caseStudies" />,
    url: 'case-studies'
  },
  {
    id: 3,
    name: <FormattedMessage id="contact" />,
    url: 'contact'
  },
  {
    id: 4,
    name: <FormattedMessage id="partners" />,
    url: 'partners'
  }
];

const overOns = [
  {
    id: 1,
    name: <FormattedMessage id="team" />,
    url: 'team'
  },
  {
    id: 2,
    name: <FormattedMessage id="media" />,
    url: 'pers-en-media'
  },
  {
    id: 3,
    name: <FormattedMessage id="vacancies" />,
    url: 'vacatures'
  },
  {
    id: 4,
    name: "Jochem.ai",
    url: 'jochem-ai'
  }
];

const juridisch = [
  {
    id: 1,
    name: <FormattedMessage id="privacy" />,
    url: 'privacy'
  },
  {
    id: 2,
    name: <FormattedMessage id="cookies" />,
    url: 'cookies'
  },
  {
    id: 3,
    name: <FormattedMessage id="tos" />,
    url: 'algemene-voorwaarden'
  }
];

const navItems = [
  { id: 1, placeholder: <FormattedMessage id="solutions" />, navItems: oplossingen },
  { id: 2, placeholder: <FormattedMessage id="resources" />, navItems: resources },
  { id: 3, placeholder: <FormattedMessage id="aboutUs" />, navItems: overOns },
  { id: 4, placeholder: <FormattedMessage id="legal" />, navItems: juridisch }
];

const mobileNavItems = [
  { id: 1, placeholder: <FormattedMessage id="solutions" />, navItems: oplossingen },
  { id: 2, placeholder: <FormattedMessage id="resources" />, navItems: resources },
  { id: 3, placeholder: <FormattedMessage id="aboutUs" />, navItems: overOns }
];

export { navItems, oplossingen, mobileNavItems };
